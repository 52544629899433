<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-img :src="gymoverview" class="my-3" height="400" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1>Facility Information</h1>
          <p>
            We have everything you need to run an efficent indoor practice. We
            offer 6 retactable cages that come fully equipped with:
          </p>

          <ul>
            <li>L-Screen</li>
            <li>Ball Cart</li>
            <li>Tanner Tee</li>
            <li>Hitting Mat</li>
            <li>Baseballs or Softballs</li>
          </ul>
          <br />
          <p>
            We also have 2 portable baseball pitching mounds for players/teams
            to use.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h1>Facility Rental Options</h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="Lscreen"
            >
            </v-img>
            <v-card-text class="text--primary text-center">
              <h3>Individual Cage Rental</h3>
              <p>
                30 minutes - $20.00 <br />
                60 minutes - $40.00
              </p>
              <br />
              <ContactButton title="Contact Us" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="gymoverview"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Whole Turf Area (6 Cages)</h3>
              <p>
                90 minutes - $200.00 <br />
                (Weekends Only)
              </p>
              <br />

              <ContactButton title="Contact Us" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400" min-height="152">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="gymoverview"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Team Winter Practices</h3>
              <p>
                If your team practices regularly, lock your practice times in
                now!
              </p>
              <br />

              <ContactButton title="Winter Schedule" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-card hover class="mx-auto" max-width="400">
            <v-img
              class="white--text align-end"
              height="320px"
              :src="andrewmound"
            >
            </v-img>

            <v-card-text class="text--primary text-center">
              <h3>Special Events</h3>
              <p>
                Contact us to host an event or birthday party at the facility!
              </p>
              <br />

              <ContactButton title="Let's Party" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h1>Facility Rules</h1>
          <p>
            We ask all of our members and guests to treat the facility like it
            is your own. Here are some rules to remember when at the facility:
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Overall Absolutes</h4>
          <ol>
            <li>No Gum or Sunflower Seeds inside the facility.</li>
            <li>No food or drinks are allowed on the turf/gym area.</li>
            <li>
              No tobacco products of any kind are allowed in the facility.
            </li>
          </ol>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Common Curtesy</h4>
          <ol>
            <li>Please throw away all trash.</li>
            <li>Please clean off shoes before entering the facility.</li>
            <li>
              Please respect everyone and all of the equipment in the facility.
            </li>
          </ol>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Gym Rules</h4>
          <ol>
            <li>No horseplay in the gym area.</li>
            <li>Please put back all the gym equipment after use.</li>
            <li>
              No strength training is allowed without a Born Again Strength
              trainer's supervision. (Regardless of age)
            </li>
          </ol>
        </v-col>
        <v-col cols="12" md="6">
          <h4>Turf Rules</h4>
          <ol>
            <li>Please do not hang on the nets.</li>
            <li>All athletes must be under adult supervision.</li>
            <li>
              Please put back all the baseball equipment after use (Tee, Balls,
              Ball Cart, L-Screen).
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ContactButton from '@/components/ContactButton.vue'
import gymoverview from '@/assets/images/gymoverview.jpg'
import andrewmound from '@/assets/images/andrewmound.jpg'
import Lscreen from '@/assets/images/Lscreen.jpg'

export default {
  components: { ContactButton },

  data() {
    return {
      gymoverview: gymoverview,
      andrewmound: andrewmound,
      Lscreen: Lscreen,
    }
  },
}
</script>

<style></style>
